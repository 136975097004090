<template>
  <img alt="Vue logo" src="./assets/art-1.jpg" width="500">
  <HelloWorld msg="Paul Ramsey Artworks"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #07213b;
  margin-top: 60px;
}
</style>
